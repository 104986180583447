*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.prose {
  color: var(--tw-prose-body);
  max-width: 65ch;
}

.prose :where([class~="lead"]):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-lead);
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  font-size: 1.25em;
  line-height: 1.6;
}

.prose :where(a):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-links);
  font-weight: 500;
  text-decoration: underline;
}

.prose :where(strong):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-bold);
  font-weight: 600;
}

.prose :where(ol):not(:where([class~="not-prose"] *)) {
  padding-left: 1.625em;
  list-style-type: decimal;
}

.prose :where(ol[type="A"]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.prose :where(ol[type="a"]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.prose :where(ol[type="A" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.prose :where(ol[type="a" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.prose :where(ol[type="I"]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.prose :where(ol[type="i"]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.prose :where(ol[type="I" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.prose :where(ol[type="i" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.prose :where(ol[type="1"]):not(:where([class~="not-prose"] *)) {
  list-style-type: decimal;
}

.prose :where(ul):not(:where([class~="not-prose"] *)) {
  padding-left: 1.625em;
  list-style-type: disc;
}

.prose :where(ol > li):not(:where([class~="not-prose"] *))::marker {
  color: var(--tw-prose-counters);
  font-weight: 400;
}

.prose :where(ul > li):not(:where([class~="not-prose"] *))::marker {
  color: var(--tw-prose-bullets);
}

.prose :where(hr):not(:where([class~="not-prose"] *)) {
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}

.prose :where(blockquote):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-quotes);
  border-left-width: .25rem;
  border-left-color: var(--tw-prose-quote-borders);
  quotes: "“""”""‘""’";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-left: 1em;
  font-style: italic;
  font-weight: 500;
}

.prose :where(blockquote p:first-of-type):not(:where([class~="not-prose"] *)):before {
  content: open-quote;
}

.prose :where(blockquote p:last-of-type):not(:where([class~="not-prose"] *)):after {
  content: close-quote;
}

.prose :where(h1):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 0;
  margin-bottom: .888889em;
  font-size: 2.25em;
  font-weight: 800;
  line-height: 1.11111;
}

.prose :where(h1 strong):not(:where([class~="not-prose"] *)) {
  font-weight: 900;
}

.prose :where(h2):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.33333;
}

.prose :where(h2 strong):not(:where([class~="not-prose"] *)) {
  font-weight: 800;
}

.prose :where(h3):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.6em;
  margin-bottom: .6em;
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.6;
}

.prose :where(h3 strong):not(:where([class~="not-prose"] *)) {
  font-weight: 700;
}

.prose :where(h4):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.5em;
  margin-bottom: .5em;
  font-weight: 600;
  line-height: 1.5;
}

.prose :where(h4 strong):not(:where([class~="not-prose"] *)) {
  font-weight: 700;
}

.prose :where(figure > *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose :where(figcaption):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-captions);
  margin-top: .857143em;
  font-size: .875em;
  line-height: 1.42857;
}

.prose :where(code):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-code);
  font-size: .875em;
  font-weight: 600;
}

.prose :where(code):not(:where([class~="not-prose"] *)):before, .prose :where(code):not(:where([class~="not-prose"] *)):after {
  content: "`";
}

.prose :where(a code):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-links);
}

.prose :where(pre):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  border-radius: .375rem;
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
  padding: .857143em 1.14286em;
  font-size: .875em;
  font-weight: 400;
  line-height: 1.71429;
  overflow-x: auto;
}

.prose :where(pre code):not(:where([class~="not-prose"] *)) {
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  background-color: #0000;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

.prose :where(pre code):not(:where([class~="not-prose"] *)):before, .prose :where(pre code):not(:where([class~="not-prose"] *)):after {
  content: none;
}

.prose :where(table):not(:where([class~="not-prose"] *)) {
  width: 100%;
  table-layout: auto;
  text-align: left;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: .875em;
  line-height: 1.71429;
}

.prose :where(thead):not(:where([class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-th-borders);
}

.prose :where(thead th):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  vertical-align: bottom;
  padding-bottom: .571429em;
  padding-left: .571429em;
  padding-right: .571429em;
  font-weight: 600;
}

.prose :where(tbody tr):not(:where([class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders);
}

.prose :where(tbody tr:last-child):not(:where([class~="not-prose"] *)) {
  border-bottom-width: 0;
}

.prose :where(tbody td):not(:where([class~="not-prose"] *)) {
  vertical-align: baseline;
  padding: .571429em;
}

.prose {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: #00000080;
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75;
}

.prose :where(p):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.prose :where(img):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(video):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(figure):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(h2 code):not(:where([class~="not-prose"] *)) {
  font-size: .875em;
}

.prose :where(h3 code):not(:where([class~="not-prose"] *)) {
  font-size: .9em;
}

.prose :where(li):not(:where([class~="not-prose"] *)) {
  margin-top: .5em;
  margin-bottom: .5em;
}

.prose :where(ol > li):not(:where([class~="not-prose"] *)) {
  padding-left: .375em;
}

.prose :where(ul > li):not(:where([class~="not-prose"] *)) {
  padding-left: .375em;
}

.prose > :where(ul > li p):not(:where([class~="not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.prose > :where(ul > li > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.prose > :where(ul > li > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.prose > :where(ol > li > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.prose > :where(ol > li > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.prose :where(hr + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(h2 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(h3 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(h4 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(thead th:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}

.prose :where(thead th:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}

.prose :where(tbody td:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}

.prose :where(tbody td:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}

.prose > :where(:first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose > :where(:last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 0;
}

.invisible {
  visibility: hidden;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.top-4 {
  top: 1rem;
}

.left-4 {
  left: 1rem;
}

.right-4 {
  right: 1rem;
}

.bottom-4 {
  bottom: 1rem;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.col-span-full {
  grid-column: 1 / -1;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.float-right {
  float: right;
}

.m-auto {
  margin: auto;
}

.m-0 {
  margin: 0;
}

.m-1 {
  margin: .25rem;
}

.m-2 {
  margin: .5rem;
}

.m-4 {
  margin: 1rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mt-8 {
  margin-top: 2rem;
}

.ml-2 {
  margin-left: .5rem;
}

.mt-7 {
  margin-top: 1.75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.-mt-12 {
  margin-top: -3rem;
}

.-mt-\[48px\] {
  margin-top: -48px;
}

.-mt-\[36px\] {
  margin-top: -36px;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mt-auto {
  margin-top: auto;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mr-0 {
  margin-right: 0;
}

.ml-0 {
  margin-left: 0;
}

.mt-24 {
  margin-top: 6rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.h-6 {
  height: 1.5rem;
}

.h-36 {
  height: 9rem;
}

.h-0 {
  height: 0;
}

.h-\[170px\] {
  height: 170px;
}

.h-\[96px\] {
  height: 96px;
}

.h-4 {
  height: 1rem;
}

.h-12 {
  height: 3rem;
}

.h-full {
  height: 100%;
}

.h-5 {
  height: 1.25rem;
}

.max-h-\[75vh\] {
  max-height: 75vh;
}

.max-h-\[50vh\] {
  max-height: 50vh;
}

.w-min {
  width: min-content;
}

.w-full {
  width: 100%;
}

.w-0 {
  width: 0;
}

.w-\[96px\] {
  width: 96px;
}

.w-4 {
  width: 1rem;
}

.w-48 {
  width: 12rem;
}

.w-auto {
  width: auto;
}

.w-5 {
  width: 1.25rem;
}

.max-w-\[90\%\] {
  max-width: 90%;
}

.max-w-xs {
  max-width: 20rem;
}

.max-w-\[90vw\] {
  max-width: 90vw;
}

.max-w-sm {
  max-width: 24rem;
}

.flex-grow {
  flex-grow: 1;
}

.-translate-x-\[50\%\] {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.-translate-x-\[100\%\] {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.gap-2 {
  gap: .5rem;
}

.gap-4 {
  gap: 1rem;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-clip {
  overflow: clip;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-y-visible {
  overflow-y: visible;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-r-none {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rounded-l-none {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-t-md {
  border-top-left-radius: .375rem;
  border-top-right-radius: .375rem;
}

.rounded-l-lg {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-r-lg {
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.border-2 {
  border-width: 2px;
}

.border-b-\[1px\] {
  border-bottom-width: 1px;
}

.border-l-\[1px\] {
  border-left-width: 1px;
}

.border-solid {
  border-style: solid;
}

.border-primary-3 {
  --tw-border-opacity: 1;
  border-color: rgb(var(--primary-3) / var(--tw-border-opacity) );
}

.border-scheme-3 {
  --tw-border-opacity: 1;
  border-color: rgb(var(--scheme-3) / var(--tw-border-opacity) );
}

.border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity) );
}

.border-onscheme-2\/10 {
  border-color: rgb(var(--onscheme-2) / .1);
}

.border-b-scheme-3 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(var(--scheme-3) / var(--tw-border-opacity) );
}

.bg-primary-3 {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--primary-3) / var(--tw-bg-opacity) );
}

.bg-transparent {
  background-color: #0000;
}

.bg-scheme-3 {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--scheme-3) / var(--tw-bg-opacity) );
}

.bg-scheme-4 {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--scheme-4) / var(--tw-bg-opacity) );
}

.bg-scheme-5 {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--scheme-5) / var(--tw-bg-opacity) );
}

.bg-scheme-2 {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--scheme-2) / var(--tw-bg-opacity) );
}

.bg-slate-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity) );
}

.bg-slate-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity) );
}

.bg-slate-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity) );
}

.bg-slate-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity) );
}

.bg-blue-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity) );
}

.bg-mint-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(218 242 227 / var(--tw-bg-opacity) );
}

.bg-mint-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(187 219 199 / var(--tw-bg-opacity) );
}

.bg-mint-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(15 30 21 / var(--tw-bg-opacity) );
}

.bg-mint-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(29 54 39 / var(--tw-bg-opacity) );
}

.bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity) );
}

.bg-zinc-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity) );
}

.bg-zinc-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(244 244 245 / var(--tw-bg-opacity) );
}

.bg-zinc-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(24 24 27 / var(--tw-bg-opacity) );
}

.bg-zinc-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(39 39 42 / var(--tw-bg-opacity) );
}

.bg-orange-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(234 88 12 / var(--tw-bg-opacity) );
}

.bg-\[\#ffffff\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
}

.bg-\[\#f2f3f5\] {
  --tw-bg-opacity: 1;
  background-color: rgb(242 243 245 / var(--tw-bg-opacity) );
}

.bg-\[rgb\(24\,25\,28\)\] {
  --tw-bg-opacity: 1;
  background-color: rgb(24 25 28 / var(--tw-bg-opacity) );
}

.bg-\[rgb\(32\,34\,37\)\] {
  --tw-bg-opacity: 1;
  background-color: rgb(32 34 37 / var(--tw-bg-opacity) );
}

.bg-\[rgb\(88\,101\,242\)\] {
  --tw-bg-opacity: 1;
  background-color: rgb(88 101 242 / var(--tw-bg-opacity) );
}

.bg-stone-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 244 / var(--tw-bg-opacity) );
}

.bg-stone-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(231 229 228 / var(--tw-bg-opacity) );
}

.bg-stone-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(28 25 23 / var(--tw-bg-opacity) );
}

.bg-stone-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(41 37 36 / var(--tw-bg-opacity) );
}

.bg-yellow-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 204 21 / var(--tw-bg-opacity) );
}

.bg-primary-1 {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--primary-1) / var(--tw-bg-opacity) );
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity) );
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity) );
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity) );
}

.bg-scheme-1 {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--scheme-1) / var(--tw-bg-opacity) );
}

.bg-opacity-0 {
  --tw-bg-opacity: 0;
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.p-2 {
  padding: .5rem;
}

.p-1 {
  padding: .25rem;
}

.p-10 {
  padding: 2.5rem;
}

.p-4 {
  padding: 1rem;
}

.p-6 {
  padding: 1.5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.pb-0 {
  padding-bottom: 0;
}

.pt-0 {
  padding-top: 0;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-8xl {
  font-size: 6rem;
  line-height: 1;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.italic {
  font-style: italic;
}

.text-onprimary-3 {
  --tw-text-opacity: 1;
  color: rgb(var(--onprimary-3) / var(--tw-text-opacity) );
}

.text-primary-4 {
  --tw-text-opacity: 1;
  color: rgb(var(--primary-4) / var(--tw-text-opacity) );
}

.text-onscheme-2 {
  --tw-text-opacity: 1;
  color: rgb(var(--onscheme-2) / var(--tw-text-opacity) );
}

.text-onscheme-3 {
  --tw-text-opacity: 1;
  color: rgb(var(--onscheme-3) / var(--tw-text-opacity) );
}

.text-onscheme-4 {
  --tw-text-opacity: 1;
  color: rgb(var(--onscheme-4) / var(--tw-text-opacity) );
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity) );
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
}

.text-\[\#2e3338\] {
  --tw-text-opacity: 1;
  color: rgb(46 51 56 / var(--tw-text-opacity) );
}

.text-\[rgb\(220\,221\,222\)\] {
  --tw-text-opacity: 1;
  color: rgb(220 221 222 / var(--tw-text-opacity) );
}

.text-primary-3 {
  --tw-text-opacity: 1;
  color: rgb(var(--primary-3) / var(--tw-text-opacity) );
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity) );
}

.text-onscheme-1 {
  --tw-text-opacity: 1;
  color: rgb(var(--onscheme-1) / var(--tw-text-opacity) );
}

.text-onscheme-2\/25 {
  color: rgb(var(--onscheme-2) / .25);
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity) );
}

.text-opacity-30 {
  --tw-text-opacity: .3;
}

.underline {
  text-decoration-line: underline;
}

.opacity-70 {
  opacity: .7;
}

.opacity-50 {
  opacity: .5;
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline {
  outline-style: solid;
}

.outline-1 {
  outline-width: 1px;
}

.outline-scheme-5 {
  outline-color: rgb(var(--scheme-5) );
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-shadow {
  transition-property: box-shadow;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

@font-face {
  font-family: Radio Canada;
  src: url("RadioCanada-Variable.c3879664.ttf");
}

:root {
  --scheme-1: 23 23 23;
  --scheme-2: 41 37 36;
  --scheme-3: 64 64 64;
  --scheme-4: 82 82 82;
  --scheme-5: 115 115 115;
  --primary-1: 154 52 18;
  --primary-2: 194 65 12;
  --primary-3: 234 88 12;
  --primary-4: 249 115 22;
  --primary-5: 251 146 60;
  --onprimary-1: 255 255 255;
  --onprimary-2: 255 255 255;
  --onprimary-3: 255 255 255;
  --onprimary-4: 255 255 255;
  --onprimary-5: 0 0 0;
  --onscheme-1: 255 255 255;
  --onscheme-2: 255 255 255;
  --onscheme-3: 255 255 255;
  --onscheme-4: 255 255 255;
  --onscheme-5: 255 255 255;
}

body, html {
  height: 100%;
  width: 100%;
  font-family: Radio Canada, Arial, Helvetica, sans-serif;
}

.X-Column {
  display: inline;
}

.clip {
  clip: rect(0px, 0px, 0px, 0px);
  clip-path: rect(0px, 0px, 0px, 0px);
}

.clip-auto {
  clip: auto !important;
  clip-path: auto !important;
}

.X-Card > h1, .X-Card > h2, .X-Card > p {
  padding: .5rem 1rem;
}

.X-Card h1 {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.X-Profile-Avatar.is-primary:first-child {
  margin-top: 1rem !important;
}

.X-KVTable {
  width: 100%;
  display: block;
}

.X-KVTable .row {
  display: flex;
}

.X-KVTable .row > span {
  width: 50%;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  padding: .25rem 1rem;
  display: block;
  overflow: hidden;
}

.X-KVTable .row.X-Uneven > :first-child {
  width: 40%;
}

.X-KVTable .row.X-Uneven > :last-child {
  width: 60%;
}

.tooltip {
  visibility: hidden;
  position: absolute;
}

span.tooltip {
  visibility: hidden;
  visibility: hidden;
  display: none;
}

.has-tooltip:hover .tooltip {
  visibility: visible;
  z-index: 50;
}

.is-verified a {
  --tw-text-opacity: 1 !important;
  color: rgb(34 197 94 / var(--tw-text-opacity) ) !important;
}

.is-verified a:hover {
  text-decoration-line: underline !important;
}

.is-not-verified a {
  --tw-text-opacity: 1 !important;
  color: rgb(59 130 246 / var(--tw-text-opacity) ) !important;
}

.is-not-verified a:hover {
  text-decoration-line: underline !important;
}

.X-Arb a {
  --tw-text-opacity: 1;
  color: rgb(var(--primary-3) / var(--tw-text-opacity) );
}

.X-Arb a:hover {
  text-decoration-line: underline;
}

.X-Arb p:not(:last-child) {
  margin-bottom: 1rem;
}

.X-Arb span.invisible {
  visibility: hidden;
  display: none;
}

.X-Hide-Dark, .X-Hide-Light {
  height: 0;
  width: 0;
  margin: 0;
  padding: 0;
  display: none;
}

.text-onscheme-4h, .text-onscheme-5h {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity) );
}

dialog:not([open]), spandialog:not([open]), spandialog:not([open]) {
  visibility: hidden;
  display: none;
}

dialog[open] {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

dialog::backdrop {
  background-color: #00000080;
}

.last\:mr-4:last-child {
  margin-right: 1rem;
}

.last\:max-w-none:last-child {
  max-width: none;
}

.last\:rounded-lg:last-child {
  border-radius: .5rem;
}

.last\:rounded-b-md:last-child {
  border-bottom-left-radius: .375rem;
  border-bottom-right-radius: .375rem;
}

.focus-within\:h-auto:focus-within {
  height: auto;
}

.focus-within\:w-auto:focus-within {
  width: auto;
}

.hover\:bg-primary-5:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--primary-5) / var(--tw-bg-opacity) );
}

.hover\:bg-primary-2\/25:hover {
  background-color: rgb(var(--primary-2) / .25);
}

.hover\:bg-onscheme-2\/25:hover {
  background-color: rgb(var(--onscheme-2) / .25);
}

.hover\:bg-scheme-4:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--scheme-4) / var(--tw-bg-opacity) );
}

.hover\:bg-blue-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity) );
}

.hover\:bg-green-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(74 222 128 / var(--tw-bg-opacity) );
}

.hover\:bg-orange-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(251 146 60 / var(--tw-bg-opacity) );
}

.hover\:bg-\[rgb\(91\,101\,242\)\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(91 101 242 / var(--tw-bg-opacity) );
}

.hover\:bg-yellow-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(254 240 138 / var(--tw-bg-opacity) );
}

.hover\:bg-green-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity) );
}

.hover\:bg-red-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity) );
}

.hover\:bg-scheme-3:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--scheme-3) / var(--tw-bg-opacity) );
}

.hover\:bg-primary-3:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--primary-3) / var(--tw-bg-opacity) );
}

.hover\:bg-onscheme-1\/25:hover {
  background-color: rgb(var(--onscheme-1) / .25);
}

.hover\:bg-opacity-25:hover {
  --tw-bg-opacity: .25;
}

.hover\:text-primary-2:hover {
  --tw-text-opacity: 1;
  color: rgb(var(--primary-2) / var(--tw-text-opacity) );
}

.hover\:text-blue-500:hover {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity) );
}

.hover\:text-green-500:hover {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity) );
}

.hover\:text-red-500:hover {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity) );
}

.hover\:text-onscheme-3:hover {
  --tw-text-opacity: 1;
  color: rgb(var(--onscheme-3) / var(--tw-text-opacity) );
}

.hover\:text-primary-3:hover {
  --tw-text-opacity: 1;
  color: rgb(var(--primary-3) / var(--tw-text-opacity) );
}

.hover\:text-opacity-70:hover {
  --tw-text-opacity: .7;
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:opacity-100:hover {
  opacity: 1;
}

.hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:bg-primary-3:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--primary-3) / var(--tw-bg-opacity) );
}

.focus\:text-onprimary-3:focus {
  --tw-text-opacity: 1;
  color: rgb(var(--onprimary-3) / var(--tw-text-opacity) );
}

.focus\:outline-2:focus {
  outline-width: 2px;
}

.focus\:outline-offset-2:focus {
  outline-offset: 2px;
}

.active\:bg-primary-2:active {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--primary-2) / var(--tw-bg-opacity) );
}

.active\:bg-primary-2\/10:active {
  background-color: rgb(var(--primary-2) / .1);
}

.active\:bg-onscheme-2\/10:active {
  background-color: rgb(var(--onscheme-2) / .1);
}

.active\:bg-onscheme-1\/10:active {
  background-color: rgb(var(--onscheme-1) / .1);
}

.active\:text-onscheme-1\/75:active {
  color: rgb(var(--onscheme-1) / .75);
}

.active\:shadow-2xl:active {
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group:hover .group-hover\:underline {
  text-decoration-line: underline;
}

.dark .dark\:prose-invert {
  --tw-prose-body: var(--tw-prose-invert-body);
  --tw-prose-headings: var(--tw-prose-invert-headings);
  --tw-prose-lead: var(--tw-prose-invert-lead);
  --tw-prose-links: var(--tw-prose-invert-links);
  --tw-prose-bold: var(--tw-prose-invert-bold);
  --tw-prose-counters: var(--tw-prose-invert-counters);
  --tw-prose-bullets: var(--tw-prose-invert-bullets);
  --tw-prose-hr: var(--tw-prose-invert-hr);
  --tw-prose-quotes: var(--tw-prose-invert-quotes);
  --tw-prose-quote-borders: var(--tw-prose-invert-quote-borders);
  --tw-prose-captions: var(--tw-prose-invert-captions);
  --tw-prose-code: var(--tw-prose-invert-code);
  --tw-prose-pre-code: var(--tw-prose-invert-pre-code);
  --tw-prose-pre-bg: var(--tw-prose-invert-pre-bg);
  --tw-prose-th-borders: var(--tw-prose-invert-th-borders);
  --tw-prose-td-borders: var(--tw-prose-invert-td-borders);
}

.dark .dark\:text-primary-3 {
  --tw-text-opacity: 1;
  color: rgb(var(--primary-3) / var(--tw-text-opacity) );
}

.dark .dark\:text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity) );
}

@media print {
  .print\:hidden {
    display: none;
  }
}

@media (min-width: 768px) {
  .md\:block {
    display: block;
  }

  .md\:hidden {
    display: none;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .lg\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .lg\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:inline {
    display: inline;
  }

  .lg\:grid {
    display: grid;
  }

  .lg\:max-w-md {
    max-width: 28rem;
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:gap-4 {
    gap: 1rem;
  }
}

@media (min-width: 1280px) {
  .xl\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .xl\:max-w-lg {
    max-width: 32rem;
  }

  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (min-width: 1536px) {
  .\32 xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

/*# sourceMappingURL=404.f7e9a3f0.css.map */
